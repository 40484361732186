/* src/app/modules/cross-profile/authentication/login/login.component.scss */
* .error-message {
  height: 0px !important;
}
.email-verified-box {
  width: 100%;
  height: auto;
  background-color: #fff;
  font-size: 15px;
  color: #394551;
  font-weight: 600;
  text-align: center;
}
.auth-full {
  width: 100%;
  height: 100%;
  position: absolute;
  background:
    linear-gradient(
      0deg,
      #0f2976 0%,
      #09aee0 100%);
}
.auth {
  width: 300px;
}
.nav-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 75%;
  margin: 2rem auto;
}
.header {
  position: relative;
  font-family: "Montserrat", sans-serif !important;
}
.login-title {
  font-size: 34px;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  letter-spacing: 4.5px;
}
.login-description {
  text-transform: uppercase;
  font-weight: 600;
  color: white;
  font-size: 13px;
  text-align: center;
}
.login-welcome {
  text-align: center;
  color: #88FCF3;
  font-size: 12px;
  margin-top: 2rem;
}
.form-control {
  border: 1.4px solid #fff;
  border-radius: 25px;
  background-color: transparent;
  font-size: 14px;
  color: #fff;
}
.input-icon {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}
.input-password {
  padding: 25px 36px 25px 50px !important;
}
.input-email {
  padding: 25px 20px 25px 50px !important;
}
.password-group {
  display: flex;
  align-items: center;
}
.password-group input {
  padding-right: 42px !important;
}
.input-group-text {
  padding-top: 1px !important;
  padding-bottom: 0px !important;
}
.input-group-append {
  margin-top: 0px !important;
}
:host ::ng-deep .has-error {
  background-color: rgba(255, 255, 255, 0) !important;
  border-color: #fff !important;
}
:host ::ng-deep .text-danger {
  color: #fff !important;
  position: static;
  text-align: center;
}
.forgot-password {
  font-size: 12px;
  color: white;
}
.btn-submit {
  border-radius: 25px;
  background-color: transparent;
  padding: 10px 40px;
  font-size: 14px;
  color: #67fefe;
  text-transform: uppercase;
  border: 1.8px solid #fff;
  font-weight: bold;
  display: table;
  margin: 0 auto;
}
.btn-submit:hover {
  background-color: #fff;
  color: #13243b;
}
.btn-submit:disabled {
  background-color: transparent;
  color: #67fefe;
}
.back-to-login {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
}
.back-to-login i {
  font-size: 30px;
  margin-right: 10px;
}
.login-version-tag {
  position: absolute;
  right: 10px;
  bottom: 5px;
}
.cookies-dialog-login {
  position: absolute;
  right: 55px;
  bottom: 6.6px;
  color: white;
  cursor: pointer;
  font-size: 10px !important;
}
::placeholder {
  color: white !important;
}
:-ms-input-placeholder {
  color: white !important;
}
::-ms-input-placeholder {
  color: white !important;
}
@media screen and (max-width: 1600px) {
  .nav-actions {
    max-width: 100%;
    margin-right: 4rem;
  }
}
/*# sourceMappingURL=login.component-MAJNYLHU.css.map */
