<div class="logotipo-full relative">
    <h1 class="logotipo">
        <img src="../../../../../assets/images/finstein-big.svg" alt="">
    </h1>
    
    <div class="header-icons">
        <img class="block-1" alt="block-1" *ngIf="showBlocks" src="../../../../../assets/images/auth-icons/block-1.svg" />
        <img class="block-2" alt="block-2" *ngIf="showBlocks" src="../../../../../assets/images/auth-icons/block-2.svg" />
        <img class="block-3" alt="block-3" *ngIf="showBlocks" src="../../../../../assets/images/auth-icons/block-2.svg" />
        <img class="plus-1" alt="plus-1" *ngIf="showPlus" src="../../../../../assets/images/auth-icons/plus-1.svg" />
        <img class="plus-2" alt="plus-2" *ngIf="showPlus" src="../../../../../assets/images/auth-icons/plus-2.svg" />
        <img class="plus-3" alt="plus-3" *ngIf="showPlus" src="../../../../../assets/images/auth-icons/plus-3.svg" />
        <img class="plus-4" alt="plus-4" *ngIf="showPlus" src="../../../../../assets/images/auth-icons/plus-4.svg" />
        <img class="plus-5" alt="plus-5" *ngIf="showPlus" src="../../../../../assets/images/auth-icons/plus-3.svg" />
        <img class="plus-6" alt="plus-6" *ngIf="showPlus" src="../../../../../assets/images/auth-icons/plus-6.svg" />
        <img class="plus-7" alt="plus-7" *ngIf="showPlus" src="../../../../../assets/images/auth-icons/plus-3.svg" />
        <img class="plus-8" alt="plus-8" *ngIf="showPlus" src="../../../../../assets/images/auth-icons/plus-7.svg" />
    </div>
</div>