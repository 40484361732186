/* src/app/shared/components/animated-logotipo/animated-logotipo.component.scss */
.logotipo-full {
  width: 290px;
  height: 184px;
  display: table;
  margin: 0 auto;
}
.logotipo {
  width: 160px;
  height: auto;
  display: table;
  margin: 0 auto;
}
.logotipo img {
  max-width: 100%;
  height: auto;
}
.header-icons * {
  position: absolute;
}
.header-icons .block-1 {
  top: -40%;
  left: -20%;
  animation: floating 7s infinite;
}
.header-icons .block-2 {
  top: 5%;
  right: -30%;
  animation: floating 3s infinite;
}
.header-icons .block-3 {
  top: 115%;
  left: 100%;
  animation: floating 11.2s infinite;
}
.header-icons .plus-1 {
  opacity: 1;
  top: -40%;
  left: 80%;
  animation: fadeOut 4s infinite;
}
.header-icons .plus-2 {
  opacity: 1;
  top: -5%;
  left: 65%;
  animation: fadeOut 6s infinite;
}
.header-icons .plus-3 {
  opacity: 1;
  top: -2%;
  left: 15%;
  animation: fadeOut 2.3s infinite;
}
.header-icons .plus-4 {
  opacity: 1;
  top: 35%;
  left: 5%;
  animation: fadeOut 3s infinite;
}
.header-icons .plus-5 {
  opacity: 1;
  top: 65%;
  left: 80%;
  animation: fadeOut 3.7s infinite;
}
.header-icons .plus-6 {
  opacity: 1;
  top: 60%;
  left: -38%;
  animation: fadeOut 4.6s infinite;
}
.header-icons .plus-7 {
  opacity: 1;
  top: 80%;
  left: 15%;
  animation: fadeOut 7s infinite;
}
.header-icons .plus-8 {
  opacity: 1;
  top: 88%;
  left: 75%;
  animation: fadeOut 6.8s infinite;
}
@keyframes floating {
  0% {
    transform: translateY(0%);
    opacity: 0;
  }
  95% {
    transform: translateY(-45%);
    opacity: 1;
  }
  100% {
    transform: translateY(-45%);
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  95% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/*# sourceMappingURL=animated-logotipo.component-3HSP7AGK.css.map */
