import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-animated-logotipo',
  templateUrl: './animated-logotipo.component.html',
  styleUrls: ['./animated-logotipo.component.scss']
})
export class AnimatedLogotipoComponent {

  @Input() showPlus = false;
  @Input() showBlocks = false;


}
